import { useEffect } from "react"

function Toast({ toast, setToast }) {

    const timeout = () => {
        setTimeout(() => {
            setToast('')
        }, 4000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(timeout, [toast])

    return (
        toast &&
        <span className='toast'>
            <p>{toast}</p>
        </span>
    )
}

export default Toast