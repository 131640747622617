import Spring from '../assets/Spring.jpeg'
import Portrait from '../assets/smaller_portrait.jpeg'

function Intro() {
    return (
        <section className='intro'>
            <img className='introImg'
                src={Spring}
                alt='Intro background'
            />
            <div className='hero'>
                <span className='title'>
                    <h1>Ben Bradfield</h1>
                    <h2>Software Engineer</h2>
                </span>
                <div className='portrait'>
                    <img className='portraitImg'
                        src={Portrait}
                        alt='Ben Bradfield portrait'
                    />
                </div>
            </div>
        </section>
    )
}

export default Intro