function Modal({vid, setVid}) {
    return (
        <div className='modal'>
            <video controls muted>
                <source src={vid} type='video/mp4' />
            </video>
            <span className='closeVid' onClick={()=>setVid(null)}>Close</span>
        </div>
    )
}

export default Modal