import LinkedIn from '../assets/devicons/linkedin-original.svg'
import GitHub from '../assets/devicons/github-original.svg'
import Resume from '../assets/file.png'
import Gmail from '../assets/gmail.png'

function Contact({ setToast }) {

    const handleEmail = (e) => {
        const email = e.target.alt
        navigator.clipboard.writeText(email)
        setToast(`${email} copied to clipboard!`)
    }

    return (
        <section className='contact'>
            <span className='contactMsg'>
                <h2>Contact Me!</h2>
                <h3>Let's build something great!</h3>
            </span>
            <span className='contactLinks'>
                <a href='https://www.linkedin.com/in/ben-bradfield/' target='_blank' rel='noreferrer'>
                    <img className='contactIcon' src={LinkedIn} alt='LinkedIn Link' />
                </a>
                <a href='https://github.com/bbrad3' target='_blank' rel='noreferrer'>
                    <img className='contactIcon' src={GitHub} alt='GitHub Link' />
                </a>

                <a href='https://docs.google.com/document/d/1dpOjCj0cXCbXYG7n1CD-hrv1HWHh8z5TWITp4K-jM58/edit?usp=sharing' target='_blank' rel='noreferrer'>
                    <img className='contactIcon' src={Resume} alt='Resume Link' />
                </a>

                <img className='contactIcon' src={Gmail} alt='bbradfield3@gmail.com'
                    onClick={handleEmail}
                />
            </span>
        </section>
    )
}

export default Contact