import HTML5 from '../assets/devicons/html5-original-wordmark.svg'
import CSS3 from '../assets/devicons/css3-original-wordmark.svg'
import JS from '../assets/devicons/javascript-original.svg'
import Python from '../assets/devicons/python-original.svg'
import Bootstrap from '../assets/devicons/bootstrap-plain-wordmark.svg'
import MaterialUI from '../assets/devicons/materialui-original.svg'
import Sass from '../assets/devicons/sass-original.svg'
import ReactIcon from '../assets/devicons/react-original-wordmark.svg'
import Node from '../assets/devicons/nodejs-original-wordmark.svg'
import Express from '../assets/devicons/express-original-wordmark.svg'
import Flask from '../assets/devicons/flask-original-wordmark.svg'
import SQLA from '../assets/devicons/sqlalchemy-original-wordmark.svg'
import MongoDB from '../assets/devicons/mongodb-original-wordmark.svg'
import Postgres from '../assets/devicons/postgresql-original-wordmark.svg'
import Git from '../assets/devicons/git-original-wordmark.svg'
import GitHub from '../assets/devicons/github-original-wordmark.svg'
import Heroku from '../assets/devicons/heroku-original-wordmark.svg'
import Figma from '../assets/devicons/figma-original.svg'

function Skills() {
    return (
        <section className='skills'>
            <span className='languages'>
                <img className='skillIcon' src={HTML5} alt='html5' />
                <img className='skillIcon' src={CSS3} alt='css3' />
                <img className='skillIcon' src={JS} alt='javascript' />
                <img className='skillIcon' src={Python} alt='python' />
            </span>
            <span className='frameworks'>
                <img className='skillIcon' src={Bootstrap} alt='bootstrap' />
                <img className='skillIcon' src={MaterialUI} alt='materialui' />
                <img className='skillIcon' src={Sass} alt='sass' />
                <img className='skillIcon' src={ReactIcon} alt='react' />
                <img className='skillIcon' src={Node} alt='nodejs' />
                <img className='skillIcon' src={Express} alt='express' />
                <img className='skillIcon' src={Flask} alt='flask' />
                <img className='skillIcon' src={SQLA} alt='sqlalchemy' />
            </span>
            <span className='databases'>
                <img className='skillIcon' src={MongoDB} alt='mongodb' />
                <img className='skillIcon' src={Postgres} alt='postgresql' />
            </span>
            <span className='misc'>
                <img className='skillIcon' src={Git} alt='git' />
                <img className='skillIcon' src={GitHub} alt='github' />
                <img className='skillIcon' src={Heroku} alt='heroku' />
                <img className='skillIcon' src={Figma} alt='figma' />
            </span>
        </section>
    )
}

export default Skills