function About() {
    return (
        <section className='about'>
            {/* <h2>About Me</h2> */}
            <span className='aboutBody'>
                <section className='funStatement'>
                    <span className='statementHeading'>
                        <h3>My Life B.C.</h3>
                        <h4><em>(before covid)</em></h4>
                    </span>
                    <p>Before, the event, I was a geologist working as an environmental consultant. I was able to use my passion for the outdoors to do good for our environment. However, I was unfortunately laid off due to the effects of the pandemic...</p>

                    <span className='statementHeading'>
                        <h3>My Life A.C.</h3>
                        <h4><em>(after covid)</em></h4>
                    </span>
                    <p>...so what have I done? Watched everything on Netflix? Check. Try a new recipe? Check. Learn a new skill? Yes! And what an exciting skill it is! Software developement has become my new passion to explore my creativity, challange my mind, and to provide an important service to employers and clients!</p>
                </section>
                <section className='brandStatement'>
                    <p>I am a software engineer where I apply a go-getter attitude. I am motivated by challenging prospects. When I see a cliff, I want to climb to the top. The joy of completing a challenging goal and bringing others along with me gives me a rush worth chasing after. I apply these values through JavaScript libraries and frameworks to push the boundaries, uplift my team, and to create unique and groundbreaking projects.</p>
                </section>
            </span>
        </section>
    )
}

export default About