import { useState } from 'react'

import './App.css';
import Intro from './components/Intro'
import About from './components/About'
import Projects from './components/Projects'
import Skills from './components/Skills';
import Contact from './components/Contact';
import Toast from './components/Toast';

function App() {

  const [toast, setToast] = useState('')

  return (
    <div className="App">
      <Intro />
      <About />
      <Projects />
      <Skills />
      <Contact
        setToast={setToast}
      />
      <Toast
        toast={toast}
        setToast={setToast}
      />
    </div>
  );
}

export default App;
