
import Zombies from '../assets/projects/ZombieRunner.png'
import ZombiesMov from '../assets/projects/ZombieRunner-walkthrough.mp4'
import Blog from '../assets/projects/Blog.png'
import Weather from '../assets/projects/WeatherAPI.png'
import WeatherMov from '../assets/projects/Python-Weather-walkthrough.mp4'
import Dispatched from '../assets/projects/Dispatched.png'
import DispatchedMov from '../assets/projects/Dispatched-walkthrough.mp4'
import ProjectCard from "./ProjectCard"

function Projects() {

    const projects = [
        {
            id: 1,
            title: 'Zombie Runner',
            img: Zombies,
            walkthrough: ZombiesMov,
            desc: "One of my first games! Find the treasure...don't get eaten by the zombies!",
            stack: ['HTML5', 'Canvas', 'CSS3', 'JavaScript'],
            liveUrl: 'https://bbrad3.github.io/Zombie_Runner/',
            repoUrl: 'https://github.com/bbrad3/Zombie_Runner'
        },
        {
            id: 2,
            title: 'Weather API',
            img: Weather,
            walkthrough: WeatherMov,
            desc: "A web app integrating an external weather API",
            stack: ['React', 'CSS3', 'JavaScript', 'Python', 'Flask', 'PostgresQL'],
            liveUrl: '',
            repoUrl: ''
        },
        {
            id: 3,
            title: 'Blog',
            img: Blog,
            desc: "A simple blog site to post and share ideas.",
            stack: ['React', 'CSS3', 'JavaScript', 'Express', 'PostgresQL'],
            liveUrl: 'https://react-blog-fe.herokuapp.com/',
            repoUrl: 'https://github.com/bbrad3/Blog-FE'
        },
        {
            id: 4,
            title: 'Dispatched',
            img: Dispatched,
            walkthrough: DispatchedMov,
            desc: "Application to simplify the dispatch process for shuttle companies.",
            stack: ['React', 'CSS3', 'Socket.io', 'Express', 'PostgresQL'],
            liveUrl: 'https://dispatchedss.herokuapp.com/',
            repoUrl: 'https://github.com/bbrad3/Dispatched-WA-FE'
        },
        // {
        //     id: 5,
        //     title: 'eCommerce',
        //     img: Blog,
        //     desc: "eCommerce site with Stripe implementation.",
        //     stack: '',
        //     liveUrl: 'https://react-blog-fe.herokuapp.com/',
        //     repoUrl: 'https://github.com/bbrad3/Blog-FE'
        // },
        // {
        //     id: 6,
        //     title: 'Python',
        //     img: Blog,
        //     desc: "eCommerce site with Stripe implementation.",
        //     stack: '',
        //     liveUrl: 'https://react-blog-fe.herokuapp.com/',
        //     repoUrl: 'https://github.com/bbrad3/Blog-FE'
        // },
    ]

    return (
        <section className='projects'>
            <h2>Projects...</h2>
            <div className='projectContainer'>
                {projects.map(project => (
                    <ProjectCard key={project.id} project={project} />
                ))}
            </div>
        </section>
    )
}

export default Projects