import { useState } from 'react'
import Modal from './Modal'

function ProjectCard({ project }) {

    const [vid, setVid] = useState(null)

    return (
        <>
            {vid && <Modal vid={vid} setVid={setVid} />}
            <div className='projectCard'>
                <h3 className='cardTitle'>{project.title}</h3>
                <span className='cardImg' onClick={() => setVid(project.walkthrough)}>
                    <img src={project.img} alt='Project cover' />
                </span>
                <span className='cardBody'>
                    <p className='cardDesc'>{project.desc}</p>
                    <p className='cardStack'>
                        {project.stack.map((t, i) => (
                            <span key={i} className='stackItem'>{t}</span>
                        ))}
                    </p>
                </span>
                <span className='cardLinks'>
                    <a href={project.liveUrl} target='_blank' rel='noreferrer'>
                        Go Live
                    </a>
                    <a href={project.repoUrl} target='_blank' rel='noreferrer'>
                        GitHub Repo
                    </a>
                </span>
            </div>
        </>
    )
}

export default ProjectCard